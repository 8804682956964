import { useEffect, useReducer, useRef } from "react";
import { ReactSortable } from "react-sortablejs";
import { Item } from "../utils";
import SearchIngredients, { IngredientQuery } from "./SearchIngredients";
import ListItem from "./ListItem";
import { uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import {
  IngredientOrderByFieldEnum,
  IngredientType,
  LocaleEnum,
  SortingDirectionEnum,
  useGetIngredientsQuery,
} from "../../../generated/graphql";
import { TranslatorNS } from "../../../i18n/const";
import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router";

enum ActionType {
  UPDATE_QUERY_PARAMS = "UPDATE_QUERY_PARAMS",
  LOAD_MORE_INGREDIENTS = "LOAD_MORE_INGREDIENTS",
  UPDATE_INGREDIENT_LIST = "UPDATE_INGREDIENT_LIST",
}

export interface IngredientListState {
  searchText: string;
  onlyMine?: boolean;
  page: number;
  limit: number;
  ingredients: IngredientType[];
  moreToLoad: boolean;
  loading: boolean;
}

function reducer(
  state: IngredientListState,
  action: { type: ActionType; payload: Partial<IngredientListState> },
) {
  switch (action.type) {
    case ActionType.UPDATE_QUERY_PARAMS:
      return { ...state, ...action.payload, page: 1 };
    case ActionType.LOAD_MORE_INGREDIENTS:
      return { ...state, ...action.payload, loading: true };
    case ActionType.UPDATE_INGREDIENT_LIST:
      const shouldAppend = state.page > 1;
      const moreToLoad = action.payload.ingredients?.length === state.limit;
      if (shouldAppend) {
        return {
          ...state,
          ingredients: uniqBy(
            [...state.ingredients, ...(action.payload.ingredients || [])],
            "id",
          ),
          moreToLoad,
          loading: false,
        };
      }
      return {
        ...state,
        ingredients: action.payload.ingredients || [],
        moreToLoad,
        loading: false,
      };

    default:
      throw new Error("Unknown action");
  }
}

const initialState: IngredientListState = {
  ingredients: [],
  searchText: "",
  limit: 25,
  page: 1,
  moreToLoad: true,
  loading: true,
};

function IngredientsList({ locale }: { locale: LocaleEnum }) {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, error, data } = useGetIngredientsQuery({
    variables: {
      pagination: { limit: state.limit, page: state.page },
      filters: { search: state.searchText, locale, onlyMine: Boolean(state.onlyMine) },
      orderBy: {
        field: IngredientOrderByFieldEnum.Id,
        sort: SortingDirectionEnum.Asc,
      },
    },
  });
  const updateIngredientList = (payload: any) => {
    dispatch({
      type: ActionType.UPDATE_INGREDIENT_LIST,
      payload: { ingredients: payload },
    });
  };
  useEffect(() => {
    if (Array.isArray(data?.getIngredients?.data)) {
      updateIngredientList(data?.getIngredients?.data);
    }
  }, [loading, state.page, state.searchText, state.limit, state.onlyMine]);

  const containerScrollRef = useRef<HTMLDivElement>(null);
  const { page, ingredients, moreToLoad } = state;

  const setQueryParams = (payload: Partial<IngredientQuery>) => {
    dispatch({ type: ActionType.UPDATE_QUERY_PARAMS, payload });
  };

  const loadMore = () => {
    if (!state.loading && moreToLoad) {
      dispatch({ type: ActionType.LOAD_MORE_INGREDIENTS, payload: { page: page + 1 } });
    }
  };

  return (
    <>
      <SearchIngredients setQueryParams={setQueryParams} />
      <div
        ref={containerScrollRef}
        style={{ height: "calc(100vh - 168px)", overflowY: "auto" }}>
        <InfiniteScroll
          loadMore={loadMore}
          useWindow={false}
          hasMore={moreToLoad}
          threshold={350}
          getScrollParent={() => containerScrollRef.current}>
          <ReactSortable
            list={ingredients.map(item => ({ ...item, chosen: true }))}
            onChoose={() => {}}
            onUnchoose={() => {}}
            setList={() => {}}
            animation={150}
            sort={false}
            // multiDrag
            ghostClass="ghost"
            group={{
              name: "cloning-group-name",
              pull: "clone",
              put: false,
            }}
            // filter=".disabled"
            clone={item => ({ ...item })}>
            {ingredients.map(item => (
              <Item key={item.id}>
                <ListItem
                  title={item.name as string}
                  subTitle={t("generateMealPlan.sharedPCFGram", {
                    ns: TranslatorNS.MEAL_PLAN,
                    protein: item.protein,
                    carbs: item.carbohydrates,
                    fat: item.fat,
                  })}
                  kcal={item.kcal as number}
                />
              </Item>
            ))}
          </ReactSortable>
        </InfiniteScroll>
      </div>
    </>
  );
}

/***
 * Ensure user locale has loaded before rendering first search
 * Could add loader screen here
 */
const RECIPE_QUERY = gql`
  query getRecipe($id: Int!) {
    recipe(id: $id, locale: null) {
      locale
    }
  }
`;
function IngredientListContainer() {
  const { templateId } = useParams<{ templateId: string }>();
  const { data, loading } = useQuery(RECIPE_QUERY, {
    variables: { id: Number(templateId) },
  });

  if (loading || !data?.recipe?.locale) return null;

  return <IngredientsList locale={data.recipe.locale} />;
}

export default IngredientListContainer;
