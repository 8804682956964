import { Alert, Button, Link } from "@mui/material";
import { differenceInDays } from "date-fns";
import { CSSProperties } from "react";

const SUNSET_DATE = new Date("2025-03-01");

type Props = {
  alertSx: CSSProperties;
};

export default function SunsetAlert(props: Props) {
  const { alertSx } = props;
  console.log(SUNSET_DATE);
  const daysLeft = differenceInDays(SUNSET_DATE, new Date());
  const message = `1.0 is closing in ${daysLeft} days, switch to 2.0 now`;

  return (
    <Alert
      severity="info"
      variant="outlined"
      sx={alertSx}
      action={
        <Button
          component={Link}
          variant="text"
          sx={{ color: "#C1F65D" }}
          size="small"
          href="https://help.zenfit.io/en/articles/10248790-zenfit-1-0-will-shut-down-start-your-switch-to-zenfit-2-0-today"
          target="_blank">
          {"Learn more"}
        </Button>
      }>
      {message}
    </Alert>
  );
}
